import { Translate, TranslationQuery } from 'next-translate';
import { create } from 'zustand';

export interface Translations {
  isLoading: boolean;
  setIsLoading(value: boolean): void;
  isReady: boolean;
  setIsReady: (value: boolean) => void;
  locale: string;
  setLocale: (value: string) => void;
  url: Translate;
  setUrl: (value: Translate) => void;
  multiple: Translate;
  setMultiple: (value: Translate) => void;
  workplace: Translate;
  setWorkplace: (value: Translate) => void;
  market: Translate;
  setMarket: (value: Translate) => void;
  career_stage: Translate;
  setCareerStage: (value: Translate) => void;
  sector: Translate;
  setSector: (value: Translate) => void;
  theme_topic: Translate;
  setThemeTopic: (value: Translate) => void;
  aria: Translate;
  setAria: (value: Translate) => void;
  category: Translate;
  setCategory: (value: Translate) => void;
  common: Translate;
  setCommon: (value: Translate) => void;
  content_type: Translate;
  setContentType: (value: Translate) => void;
  location: Translate;
  setLocation: (value: Translate) => void;
  segment: Translate;
  setSegment: (value: Translate) => void;
  service_line: Translate;
  setServiceLine: (value: Translate) => void;
  service: Translate;
  setService: (value: Translate) => void;
  industry: Translate;
  setIndustry: (value: Translate) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const emptyTranslate = <T extends unknown = string>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  i18nKey: string | TemplateStringsArray,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  query?: TranslationQuery | null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  options?:
    | {
        returnObjects?: boolean | undefined;
        fallback?: string | string[] | undefined;
        default?: string | T | undefined;
        ns?: string | undefined;
      }
    | undefined,
) => '' as T;

export const withTranslations = create<Translations>((set) => ({
  isLoading: true,
  setIsLoading: (value) => set({ isLoading: value }),
  isReady: false,
  setIsReady: (value) => set({ isReady: value }),
  locale: process.env.NEXT_PUBLIC_DEFAULT_LOCALE!,
  setLocale: (value) => set({ locale: value }),
  url: emptyTranslate,
  setUrl: (value) => set({ url: value }),
  multiple: emptyTranslate,
  setMultiple: (value) => set({ multiple: value }),
  market: emptyTranslate,
  setMarket: (value) => set({ market: value }),
  workplace: emptyTranslate,
  setWorkplace: (value) => set({ workplace: value }),
  career_stage: emptyTranslate,
  setCareerStage: (value) => set({ career_stage: value }),
  sector: emptyTranslate,
  setSector: (value) => set({ sector: value }),
  theme_topic: emptyTranslate,
  setThemeTopic: (value) => set({ theme_topic: value }),
  aria: emptyTranslate,
  setAria: (value) => set({ aria: value }),
  category: emptyTranslate,
  setCategory: (value) => set({ category: value }),
  common: emptyTranslate,
  setCommon: (value) => set({ common: value }),
  content_type: emptyTranslate,
  setContentType: (value) => set({ content_type: value }),
  location: emptyTranslate,
  setLocation: (value) => set({ location: value }),
  segment: emptyTranslate,
  setSegment: (value) => set({ segment: value }),
  service_line: emptyTranslate,
  setServiceLine: (value) => set({ service_line: value }),
  service: emptyTranslate,
  setService: (value) => set({ service: value }),
  industry: emptyTranslate,
  setIndustry: (value) => set({ industry: value }),
}));
