import Redis, { RedisOptions } from 'ioredis';

import configuration from './configuration';

function getRedisConfiguration(): {
  port: string | undefined;
  host: string | undefined;
  password: string | undefined;
} {
  return configuration;
}

const config = getRedisConfiguration();

const useTLS = Number(config.port) === 6380;
const options: RedisOptions = {
  host: config.host,
  port: config.port ? Number(config.port) : 6380,
  password: config.password,
  lazyConnect: true,
  tls: useTLS as any,
  showFriendlyErrorStack: true,
  enableAutoPipelining: true,
  keyPrefix: `${process.env.NEXT_PUBLIC_APP_ENV}:`,
  connectTimeout: 1000,
  maxRetriesPerRequest: 3,
  retryStrategy: (times: number) => {
    if (times > 2) {
      console.warn(`[Redis] Could not connect after ${times} attempts`);
      return null;
    }
    return times;
  },
};

const redis = new Redis(options);

redis.on('connect', () => {
  console.log('connected to redis successfully!');
});

redis.on('error', (error) => {
  console.log('Redis connection error :', error);
});

const returnRedisInstance = async () => {
  return await redis
    .call('PING')
    .then(() => redis)
    .catch(() => null);
};

export default returnRedisInstance;
