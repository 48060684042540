import httpAdapter from '@kontent-ai/core-sdk/node_modules/axios/lib/adapters/http';
import settle from '@kontent-ai/core-sdk/node_modules/axios/lib/core/settle';
import { EXPIRY_MS, MAX_AGE } from 'lib/redis/redis';
import redis from 'lib/redis/redisLib';
import safeJsonStringify from 'safe-json-stringify';

import { getHost } from './getHost';
import { getParamsFromURL } from './getParamsFromURL';
import { getSHA256Hash } from './getSHA';

const isServer = () => {
  return !(typeof window != 'undefined' && window.document);
};

export const customAdapter = async (config) => {
  let promiseVal;
  const redisKey = await getSHA256Hash(getParamsFromURL(config.url));
  const isServerFlg = isServer();
  if (!isServerFlg) {
    const host = typeof window !== 'undefined' ? getHost(window) : '';
    const redisPromise = await fetch(`${host}/api/getFromRedis?key=${redisKey}`);
    const redisData = await redisPromise.json();
    if (redisData?.responseData) {
      promiseVal = new Promise<any>((resolve, reject) => {
        settle(resolve, reject, {
          data: redisData.responseData,
          status: 200,
          statusText: 'OK',
          headers: { ...redisData.headers, FromCache: true },
          config: config,
        });
      });
    } else {
      const response = await httpAdapter(config);
      await fetch(`${host}/api/sendToRedis`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: safeJsonStringify({ data: response.data, redisKey, headers: response.headers }),
      });
      //const postResponse = await redisPromise.json();
      promiseVal = new Promise<any>((resolve, reject) => {
        settle(resolve, reject, response);
      });
    }
  } else {
    try {
      const cachedData = await (await redis())?.get(redisKey);
      if (cachedData) {
        //await redis?.quit();
        promiseVal = new Promise<any>((resolve, reject) => {
          const responseObj = JSON.parse(cachedData);
          // if (config.url == 'https://preview-deliver.kontent.ai/7c3778f1-714a-0155-9be8-162f4c282b22/taxonomies') {
          //   console.log('Problematic object is:', responseObj);
          //   fs.writeFile('foo.json', safeJsonStringify(responseObj), function (err) {
          //     if (err) {
          //       return console.log(err);
          //     }
          //     console.log('The file was saved!');
          //   });
          // }
          settle(resolve, reject, {
            data: responseObj.responseData,
            status: 200,
            statusText: 'OK',
            headers: { ...responseObj.headers, FromCache: true },
            config: config,
          });
        });
      } else {
        const response = await httpAdapter(config);
        //const response_json = response.json();
        await (
          await redis()
        )?.set(
          redisKey,
          safeJsonStringify({ responseData: response.data, headers: response.headers }),
          EXPIRY_MS,
          MAX_AGE,
        );
        //await redis?.quit();
        promiseVal = new Promise<any>((resolve, reject) => {
          settle(resolve, reject, response);
        });
      }
    } catch (err) {
      console.log('Redis unavailable, calling Kontent API');
      const response = await httpAdapter(config);
      promiseVal = new Promise<any>((resolve, reject) => {
        settle(resolve, reject, response);
      });
    }
  }

  return promiseVal;
};
