import { parse } from 'qs';
export const getParamsFromURL = (url) => {
  let finalUrl;
  const projectId = process.env.NEXT_PUBLIC_KONTENT_AI_PROD_PROJECT_ID || undefined;
  if (projectId) {
    const urlArr = url.split(`/${projectId}/`);
    if (urlArr.length == 2) {
      finalUrl = urlArr[1];
    }
  } else {
    finalUrl = url;
  }
  return finalUrl ? finalUrl : url;
};

export const getParamsFromNormalURL = (url) => {
  let params;
  if (url) {
    const urlArr = url.split('?');
    if (urlArr.length == 2) {
      params = parse(urlArr[1]);
    }
  }
  return params;
};
